import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DispatchLogSummary {
    @keyColumn() id: string;
    @column() payloadJson: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate: Date;
    @column() processedDate?: Date;
    @column() dispatchNoteId?: string;
    @column() dispatchNoteDeletedDate?: string;
    @column() fromType: string;
    @column() dispatchType?: string;
    @column() workOrderId?: string;
    @column() workOrderNumber?: number;
    @column() fileToken?: string;
    @column() description?: string;
    @column() status?: string;
    @column() statusMessage?: string;
    @column() policyId?: string;
    @column() scheduledDate?: Date;
    @column() start?: Date;
    @column() end?: Date;
    @column() startTime?: string;
    @column() endTime?: string;
    @column() localStart?: Date;
    @column() localStartTime: string;
    @column() localEndTime: string;
    @column() dispatchJobId?: string;
    @column() assignedToUserId?: string;
    @column() assignedToEmail?: string;
    @column() assignedToName?: string;

}