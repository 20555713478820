import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class NoteDispatch {
    @keyColumn() id: string;
    @column() noteId: string;
    @column() isContractorPhoto?: boolean;
    @column() entityId: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() createdById: string;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate?: Date;
    @column() dispatchExportDate?: Date;
}