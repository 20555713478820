import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CommissionOpportunityContact {
    @keyColumn() id: string;
    @column() commissionOpportunityId: string;
    @column() type: string;
    @column() disposition: string;
    @column() notes: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdByid: string;
    @column() lastModifiedById: string;
}