import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import {  PaymentFileItem } from '@cogent/client/shared/models/accounting/payment-file-item.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { PaymentItem, CreditItemSummary, CreditSummary, DeferredRevenueMonthEndBalanceByAccountingArea, InvoiceItem, PremiumInvoiceSplitArgs, AccountingItem, CashOut, CashOutSummary, Chargeback, ContractorInvoicePaymentSummary, Credit, CreditItem, DeferredRevenueDateGroup, Invoice, InvoiceSummary, Payment, PaymentItemSummary, PaymentSummary, PrepaidServiceFee, StripeCard, Tag, FavorFundExpenseSummary } from '@upkeeplabs/models/cogent';
import { PromiseObserverService } from '@cogent/client/shared/services/promise-observer-service'
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';

@Injectable({ providedIn: 'root' })
export class AccountingApiService {
    splitPremiumInvoice(splitArgs: PremiumInvoiceSplitArgs) {
        return this.api.postNode(`accounting/split-premium-invoice`, splitArgs);
    }
    constructor(
        private api: ApiService
    ) { }

    replaceContractorInvoiceTags(contractorInvoiceId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`ContractorInvoice/${contractorInvoiceId}/replace-tags`, tags);
    }

    getPositivePayReport(startDate: Date, endDate: Date) {
        endDate = UtilitiesService.dayEnd(endDate);
        startDate = UtilitiesService.dayBegin(startDate);

        return this.api.getArrayNode(`accounting/positive-pay-report`, { startDate, endDate });
    }

    getCreditItemSummaries(creditId: string) {
        return this.api.getArrayNode(`CreditItemSummary`, { creditId_eq: creditId }, () => new CreditItemSummary());
    }

    deleteCreditItem(id: string) {
        return this.api.deleteNode(`CreditItem/${id}`);
    }

    undeleteCreditItem(id: string) {
        return this.api.unDeleteNode(`CreditItem/${id}`);
    }

    updateCustomerCreditCard(cardId: string, customerId: string, card: StripeCard) {
        return this.api.patchSingleDotNet(`Stripe/${cardId}/${customerId}/update-customer-credit-card`, card);
    }

    changeCustomerCreditCard(customerId: string, card: StripeCard) {
        return this.api.postNode(`stripe/change-customer-credit-card/${customerId}`, card);
    }

    getContractorInvoicePaymentItemSummaries(contractorInvoicePaymentId: string) {
        return this.api.getArrayDotNet('ContractorInvoicePaymentItemSummary', { contractorInvoicePaymentId_eq: contractorInvoicePaymentId });
    }

    async getDeferredRevenueMonthEndBalanceByAccountingArea(startDate: Date, endDate: Date) {
        const values = await this.api.getArrayNode('DeferredRevenueMonthEndBalanceByAccountingArea', {
            DeferredDate_gte: startDate,
            Deferreddate_lts: endDate,
            OrderBy: 'deferredDate,area',
        }, () => new DeferredRevenueMonthEndBalanceByAccountingArea());

        return this.groupDeferredRevenueDates(values);
    }

    getContractorInvoicePaymentSummaryReport(startDate: Date, endDate: Date) {
        return this.api.getArrayDotNet(`ContractorInvoice/contractor-payment-summary-report`, { startDate, endDate });
    }

    getExportedContractorInvoiceBatches(startDate: Date, endDate: Date, paymentMethod: string) {
        const params: any = {
            CreatedDate_gte: startDate,
            CreatedDate_lte: endDate, orderby: 'CreatedDate'
        };
        if (paymentMethod !== 'ALL') {
            params.PayablesPaymentMethod_eq = paymentMethod;
        }
        return this.api.getArrayDotNet('ContractorInvoicePaymentSummary', params);
    }

    saveChargeback(chargeback: Chargeback) {
        return this.api.postSingleDotNet(`Chargeback`, chargeback);
    }

    getChargeback(id: string): Promise<Chargeback> {
        return this.api.getSingleDotNet(`Chargeback/${id}`);
    }

    deleteChargeback(id: string): Promise<Chargeback> {
        return this.api.deleteDotNet(`Chargeback/${id}`);
    }

    undeleteChargeback(id: string) {
        return this.api.unDeleteDotNet(`Chargeback/${id}`);
    }

    markAsWon(id: string, date: Date, amount: number) {
        return this.api.patchDotNet(`Chargeback/${id}`, {
            reistatementDate: date,
            reinstatedAmount: amount
        });
    }

    unmarkAsWon(id: string) {
        return this.api.patchDotNet(`Chargeback/${id}`, {
            reistatementDate: null,
            reinstatedAmount: null
        });
    }

    transferContractorInvoice(contractorInvoiceId: string, newWorkOrderId: string) {
        return this.api.patchSingleDotNet(`ContractorInvoice/${contractorInvoiceId}`, {
            workOrderId: newWorkOrderId
        });
    }

    getContractorInvoicePaymentSummaryById(id: string) {
        return this.api.getSingleDotNet(`ContractorInvoicePaymentSummary/${id}`, null, () => new ContractorInvoicePaymentSummary());
    }

    savePrepaidServiceFee(prepaidServiceFee: PrepaidServiceFee) {
        return this.api.postSingleDotNet(`PrepaidServiceFee`, prepaidServiceFee);
    }

    getInvoicesToPrint(startDate: Date, endDate: Date, selectedAreaIds: string[]): Promise<string[]> {
        return this.api.getArrayDotNet(`InvoiceSummaryToPrint`, {
            createdDate_gte: startDate, createdDate_lte: endDate, select: 'id',
            areaId_contains: selectedAreaIds, paperless_eq: false
        });
    }

    updatePaymentItems(paymentItems: PaymentItemSummary[], paymentItemsToDelete: PaymentItemSummary[], creditPolicyId: string, creditAmount: number, paymentId: string) {
        return this.api.postSingleDotNet('Payment/update-payment-items', { paymentItems, paymentItemsToDelete, creditPolicyId, creditAmount, paymentId });
    }

    getInvoiceToPay(id: string) {
        return this.api.getSingleDotNet(`InvoiceSummary/to-pay/${id}`, null, () => new InvoiceSummary());
    }

    getScheduledInvoicesToProcess(): Promise<any[]> {
        return this.api.getArrayDotNet(`invoice/get-scheduled-invoices-to-process`);
    }

    getPaymentFile(paymentFile: any[]) {
        return this.api.postArrayDotNet('payment/get-payment-file', paymentFile, null, () => new PaymentFileItem());
    }

    savePaymentFile(paymentFile: any[]) {
        return this.api.postArrayDotNet('payment/save-payment-file', paymentFile);
    }

    getPaymentSearch(startDate: Date, endDate: Date, type: string, checkNumber: string, searchDate: string, quickbooksJournalId: string, paymentAmount: number,
        stripeId: string) {
        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);
        const params: any = { orderby: 'CreatedDate', deletedDate_eq: '{{null}}' };
        if (searchDate === 'CreatedDate') {
            params.createdDate_gte = startDate;
            params.createdDate_lte = endDate;
        } else if (searchDate === 'ExportDate') {
            params.exportDate_gte = startDate;
            params.exportDate_lte = endDate;
        }
        if (type !== 'Any') {
            params.type_eq = type;
        }

        if (paymentAmount) {
            params.amount_eq = paymentAmount;
        }

        if (quickbooksJournalId) {
            params.quickBooksJournalId_eq = quickbooksJournalId;
        }

        if (checkNumber) {
            params.checkNumber_eq = checkNumber;
        }
        if (stripeId) {
            params.paymentProcessorId_eq = stripeId;
        }
        return this.api.getArrayDotNet(`PaymentSummary`, params, () => new PaymentSummary());
    }

    unvoidPayment(id: string) {
        return this.api.patchDotNet(`payment/${id}`, {
            voidDate: null,
            voidExportDate: null,
        });
    }

    processScheduledInvoices(invoiceGroups: any[]) {
        return this.api.postSingleDotNet(`invoice/process-stripe-charges`, invoiceGroups);
    }

    getCard(cardId: string, entityId: string): Promise<StripeCard> {
        return this.api.getSingleDotNet(`Stripe/${cardId}/${entityId}/get-card`, null, () => new StripeCard());
    }

    generateInvoiceBatch(ids: string[]) {
        return this.api.postSingleDotNet(`Invoice/batch-invoice-printing`, ids);
    }

    completeInvoiceBatchPrint(batchId: string) {
        return this.api.patchSingleDotNet(`invoice/complete-invoice-print-batch/${batchId}`, null);
    }

    getPolicyInvoices(policyId: string) {
        return this.api.getArrayDotNet(`InvoiceSummary`, { policyId_eq: policyId });
    }

    getPolicyInvoices2(policyId: string) {
        return this.api.getArrayNode(`InvoicePaymentSummary`, { policyId_eq: policyId, voidDate_eq: '{{null}}', orderby: 'CreatedDate DESC' });
    }

    deleteInvoice(invoiceId: string) {
        return this.api.deleteDotNet(`Invoice/${invoiceId}`);
    }

    deleteInvoiceItem(invoiceItemId: string) {
        return this.api.deleteDotNet(`InvoiceItem/${invoiceItemId}`);
    }

    private groupDeferredRevenueDates(values: DeferredRevenueMonthEndBalanceByAccountingArea[]): DeferredRevenueDateGroup[] {
        const dates: DeferredRevenueDateGroup[] = [];
        let date: DeferredRevenueDateGroup;
        for (const value of values) {
            if (!date || date.deferredDate.getTime() !== value.deferredDate.getTime()) {
                if (date) {
                }
                date = new DeferredRevenueDateGroup();
                date.deferredDate = value.deferredDate;
                date.areas = values.filter(v => v.deferredDate.getTime() === date.deferredDate.getTime());
                date.balance = 0;
                date.subscriptionCount = 0;
                for (const area of date.areas) {
                    date.balance += area.balance;
                    date.subscriptionCount += area.subscriptionCount;
                }
                date.averageDeferred = Math.round(((date.balance / date.subscriptionCount) + Number.EPSILON) * 100) / 100;
                dates.push(date);
            }
        }

        return dates;
    }


    replaceInvoiceTags(invoiceId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Invoice/${invoiceId}/replace-tags`, tags);
    }

    getInvoice(id: string): Promise<Invoice> {
        return this.api.getSingleDotNet(`invoice/${id}`, () => new Invoice());
    }

    getInvoiceSummarybyNumber(number: number): Promise<InvoiceSummary> {
        return this.api.getSingleDotNet(`invoicesummary`, { invoiceNumber_eq: number }, () => new InvoiceSummary());
    }

    getServiceFeeInvoiceSummary(claimId: string): Promise<InvoiceSummary> {
        return this.api.getSingleDotNet(`invoicesummary`, { claimId_eq: claimId, invoiceType_eq: 'ServiceFee' }, () => new InvoiceSummary());
    }

    getInvoicesByClaimId(claimId: string): Promise<Invoice[]> {
        return this.api.getArrayDotNet(`Invoice`, { claimId_eq: claimId }, () => new Invoice());
    }

    getFavorFundBalanceByClaimId(id: string) {
        return this.api.getArrayNode(`FavorFundExpenseSummary`, { claimId_eq: id }, () => new FavorFundExpenseSummary());
    }

    applyPrepaidToExistingInvoice(invoiceId: string, prepaidId: string) {
        return this.api.postSingleDotNet(`invoice/${invoiceId}/apply-prepaid-to-invoice/${prepaidId}`);
    }

    getInvoiceItems(id: string): Promise<InvoiceItem[]> {
        return this.api.getArrayDotNet(`invoiceitem`, { invoiceId_eq: id }, () => new InvoiceItem());
    }

    getContractorInvoiceTags(contractorInvoiceId: string): Promise<Tag[]> {
        return this.api.getArrayDotNet(`ContractorInvoice/${contractorInvoiceId}/tags`);
    }

    deleteContractorInvoice(contractorInvoiceId: string) {
        return this.api.deleteDotNet(`ContractorInvoice/${contractorInvoiceId}`);
    }

    unDeleteContractorInvoice(contractorInvoiceId: string) {
        return this.api.unDeleteDotNet(`ContractorInvoice/${contractorInvoiceId}`);
    }

    addContractorInvoiceTags(contractorInvoiceId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`ContractorInvoice/${contractorInvoiceId}/add-tags`, tags);
    }

    removeContractorInvoiceTags(contractorInvoiceId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`ContractorInvoice/${contractorInvoiceId}/remove-tags`, tags);
    }


    addInvoiceTags(invoiceId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Invoice/${invoiceId}/add-tags`, tags);
    }

    removeInvoiceTags(invoiceId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Invoice/${invoiceId}/remove-tags`, tags);
    }

    getInvoiceTags(invoiceId: string) {
        return this.api.getArrayDotNet(`Invoice/${invoiceId}/tags`);
    }


    getMultipleBillingsForWorkOrder(workOrderId: string, contractorInvoiceId: string) {
        return this.api.getArrayDotNet('ContractorInvoiceSummary', {
            workOrderId_eq: workOrderId,
            id_neq: contractorInvoiceId,
            orderby: 'CreatedDate desc',
        }, () => new ContractorInvoiceSummaryClient());
    }

    updateTitleInvoiceAmount(policyId: string, newAmount: number) {
        return this.api.patchSingleDotNet(`Policy/${policyId}`, { titleInvoiceAmount: newAmount });
    }

    getContractorInvoicesClosedThisWeek() {
        const startDate = UtilitiesService.dayBegin(UtilitiesService.weekStart(new Date()));

        return this.api.getSingleDotNet('ContractorInvoice/Count', { exportDate_gte: startDate, });
    }

    getUnApprovedContractorInvoices() {
        return this.api.getArrayDotNet('ContractorInvoiceSummary', {
            approvedDate_eq: '{{null}}',
            exportDate_eq: '{{null}}',
            paidDate_eq: '{{null}}',
            orderBy: 'createdDate'
        }, () => new ContractorInvoiceSummaryClient());
    }



    getContractorInvoicesToApproveCount() {
        return this.api.getSingleDotNet<number>('ContractorInvoice/Count', {
            approvedDate_eq: '{{null}}',
            exportDate_eq: '{{null}}',
            paidDate_eq: '{{null}}'
        });
    }

    getContractorInvoiceAmountToExport() {
        return this.api.getSingleDotNet<number>('ContractorInvoice/sum/amount', {
            approvedDate_neq: '{{null}}',
            exportDate_eq: '{{null}}',
            paidDate_eq: '{{null}}'
        });
    }


    getContractorInvoiceSummaryById(id: string) {
        return this.api.getSingleDotNet(`ContractorInvoiceSummary/${id}`, null, () => new ContractorInvoiceSummaryClient());
    }

    getInvoiceSummary(id: string) {
        return this.api.getSingleDotNet(`InvoiceSummary/${id}`, null, () => new InvoiceSummary());
    }

    approvedContractorInvoiceBatch(ids: string[]) {
        return new Promise((resolve) => {
            const observer = new PromiseObserverService(() => { resolve(null); });
            ids.forEach(id => {
                observer.addPromise(this.api.patchDotNet(`ContractorInvoice/${id}`, { approvedDate: new Date() }));
            });
        });
    }

    updateContractorInvoice(id: string, value: any) {
        return this.api.patchDotNet(`ContractorInvoice/${id}`, value);
    }

    saveInvoice(invoice: Invoice): Promise<string> {
        return this.api.postIdDotNet('Invoice', invoice);
    }

    saveInvoice2(invoice): Promise<string> {
        return this.api.postIdNode(`Invoice`, invoice);
    }

    saveInvoiceItem(invoiceItem: InvoiceItem): Promise<any> {
        return this.api.postSingleDotNet(`InvoiceItem`, invoiceItem);
    }

    async saveInvoices(invoices: Invoice[]) {
        const allPromises: Promise<string>[] = [];

        for (const invoice of invoices) {
            allPromises.push(this.saveInvoice(invoice));
        }
        return await Promise.all(allPromises);
    }

    generateServiceFeeInvoice(claimId: string) {
        return this.api.patchDotNet('Claim/' + claimId + '/unwaive-service-fee', null);
    }

    savePayment(payment: Payment): Promise<string> {
        return this.api.postIdDotNet('Payment', payment);
    }

    savePaymentItem(paymentItem: PaymentItem): Promise<string> {
        return this.api.postIdDotNet('PaymentItem', paymentItem);
    }

    saveCredit(credit: Credit): Promise<string> {
        return this.api.postIdDotNet('Credit', credit);
    }

    getCreditItemsByInvoiceId(invoiceId: string): Promise<CreditItem[]> {
        return this.api.getArrayDotNet(`CreditItem`, { invoiceId_eq: invoiceId }, () => new CreditItem());
    }

    getCreditsForCustomer(customerId: string): Promise<Credit[]> {
        return this.api.getArrayDotNet(`Credit`, { customerId_eq: customerId, orderby: 'CreatedDate' }, () => new Credit());
    }

    getCreditsForPolicy(policyId: string): Promise<Credit[]> {
        return this.api.getArrayDotNet(`Credit`, { policyId_eq: policyId, orderby: 'CreatedDate' }, () => new Credit());
    }

    getCreditSummarysForPolicy(policyId: string): Promise<CreditSummary[]> {
        return this.api.getArrayNode(`CreditSummary`, { policyId_eq: policyId, orderby: 'CreatedDate' }, () => new CreditSummary());
    }

    saveCreditItem(creditItem: CreditItem): Promise<string> {
        return this.api.postIdDotNet('CreditItem', creditItem);
    }

    getInvoicesForPolicy(policyId: string): Promise<Invoice[]> {
        return this.api.getArrayDotNet('Invoice', { policyId_eq: policyId, orderBy: 'CreatedDate' }, () => new Invoice());
    }

    getScheduledPremiumInvoicesForPolicy(policyId: string): Promise<Invoice[]> {
        return this.api.getArrayDotNet('Invoice', {
            policyId_eq: policyId,
            invoiceType_eq: 'Premium',
            amountDue_gt: 0,
            scheduledDate_neq: '{{null}}',
            orderBy: 'CreatedDate'
        }, () => new Invoice());
    }

    getInvoiceForClaim(claimId: string): Promise<Invoice[]> {
        return this.api.getArrayDotNet('Invoice', { claimId_eq: claimId, voidDate_eq: '{{null}}' });
    }

    voidInvoice(invoice: Invoice) {
        return this.api.patchDotNet('Invoice/' + invoice.id, { voidDate: new Date() });
    }

    getUnpaidInvoicesForPolicy(policyId: string): Promise<InvoiceSummary[]> {
        return this.api.getArrayDotNet('InvoiceSummary',
            { policyId_eq: policyId, orderby: 'DueDate', amountDue_gt: 0, voidDate_eq: '{{null}}' },
            () => new InvoiceSummary());
    }
    getPolicyAccountingItems(policyId: string): Promise<AccountingItem[]> {
        return this.api.getArrayDotNet('accountingItem', { policyId_eq: policyId, orderby: 'Date' },
            () => new AccountingItem());
    }

    getPolicyTransferredAccountingItems(policyId: string): Promise<AccountingItem[]> {
        return this.api.getArrayDotNet('accountingItem', { policyId_neq: policyId, transferPolicyId_eq: policyId, orderby: 'Date' },
            () => new AccountingItem());
    }

    getPolicyCashOuts(policyId: string): Promise<CashOutSummary[]> {
        return this.api.getArrayDotNet('CashOutSummary', { policyId_eq: policyId, orderBy: 'CreatedDate' });
    }

    getCustomerAccountingItems(customerId: string):
        Promise<AccountingItem[]> {
        return this.api.getArrayDotNet('AccountingItem',
            { customerId_eq: customerId, orderby: 'Date' },
            () => new AccountingItem());
    }

    downloadInvoice(invoiceId: string): Promise<string> {
        return this.api.getTextDotNet('Invoice/' + invoiceId + '/html');
    }

    downloadStatement(policyId: string, endDate: Date = null): Promise<string> {
        if (!endDate) {
            endDate = new Date();
        }
        return this.api.getTextDotNet('Policy/' + policyId + '/Statement/Html', { endDate });
    }

    downloadTitleInvoice(policyId: string): Promise<string> {
        return this.api.getTextDotNet(`Policy/${policyId}/title-invoice`);
    }

    downloadCustomerStatement(customerId: string): Promise<string> {
        return this.api.getTextDotNet('Entity/' + customerId + '/customer-statement/html');
    }

    downloadPayment(paymentId: string): Promise<string> {
        return this.api.getTextDotNet('Payment/' + paymentId + '/html');
    }



    downloadCredit(creditId: string): Promise<string> {
        return this.api.getTextDotNet('Credit/' + creditId + '/html');
    }

    async createInvoicePayment(paymentItems: PaymentItem[], memo: string, customerId: string): Promise<Payment> {
        const payment = new Payment();
        payment.memo = memo;
        payment.id = UtilitiesService.newid();
        payment.amount = paymentItems.map(i => i.amount).reduce((a, b) => a + b);
        payment.customerId = customerId;

        payment.id = await this.api.postIdDotNet('Payment', payment);
        const paymentItemPromises: Promise<string>[] = [];
        paymentItems.forEach(pi => {
            pi.paymentId = payment.id;
            paymentItemPromises.push(this.api.postIdDotNet('PaymentItem', pi));
        });
        await Promise.all(paymentItemPromises);
        return payment;
    }

    deleteAppliedCredit(appliedCreditId: string): Promise<any> {
        return this.api.deleteDotNet('CreditItem/' + appliedCreditId);
    }

    deletePayment(id: string) {
        return this.api.deleteDotNet(`payment/${id}`);
    }

    undeletePayment(id: string) {
        return this.api.unDeleteDotNet(`payment/${id}`);
    }

    updatePremiumInvoices(policyId: string) {
        return this.api.patchDotNet(`Policy/${policyId}/invoice`, null);
    }

    resetPremiumInvoices(policyId: string) {
        return this.api.patchDotNet(`policy/${policyId}/reset-premium-invoices`, null);
    }

    voidPayment(paymentId: string): Promise<any> {
        return this.api.patchDotNet('Payment/' + paymentId, { voidDate: new Date() });
    }

    saveCreditPayment(policyId: string, creditItems: CreditItem[]) {
        return this.api.postSingleDotNet<Payment>('Credit/' + policyId + '/Apply', creditItems, null, () => new Payment());
    }

    distributeCredit(policyId: string) {
        return this.api.postSingleNode(`credit/${policyId}/distribute-credit`, null);
    }

    async saveCheckPaymentOrManualCC(payment: Payment, paymentItems: PaymentItem[], credit: Credit, policy: PolicySummary, effectiveDate: Date = null, isWarranty = false): Promise<Payment> {

        payment.id = await this.api.postIdDotNet('Payment', payment);

        if (credit) {
            credit.id = await this.api.postIdDotNet('Credit', credit);
        }

        if (paymentItems.length > 0) {

            const paymentItemPromises: Promise<string>[] = [];
            paymentItems.forEach(pi => {
                paymentItemPromises.push(this.api.postIdDotNet('PaymentItem', pi));
            });
            await Promise.all(paymentItemPromises);
            if (!policy.effectiveDate) {
                if (!effectiveDate) {
                    effectiveDate = new Date();
                }
                policy.effectiveDate = UtilitiesService.dayBegin(effectiveDate);
                const expirationDate = new Date(effectiveDate);
                expirationDate.setMonth(expirationDate.getMonth() + policy.monthsOfCoverage);
                policy.expirationDate = expirationDate;

                await this.api.patchDotNet(`Policy/${policy.id}`, { effectiveDate: policy.effectiveDate });
            }
            if(policy.canceledDate && isWarranty) {
                await this.api.patchDotNet(`Policy/${policy.id}`, { CanceledDate: '{{null}}' });
            }
        }

        return payment;
    }
    partialRefundCredit(creditItemId: string, amount: number) {
        return this.api.patchDotNet(`CreditItem/${creditItemId}`, { amount: amount });
    }
    getPayment(paymentId: string): Promise<Payment> {
        return this.api.getSingleDotNet('Payment/' + paymentId, null, () => new Payment());
    }

    getPaymentByFavorFundId(id: string): Promise<Payment> {
        return this.api.getSingleDotNet('Payment', { favorFundExpenseId_eq: id }, () => new Payment());
    }

    voidPaymentWithRefund(paymentId: string): Promise<any> {
        return this.api.patchDotNet('Payment/' + paymentId + '/VoidAndRefund', null);
    }

    async voidCheckPaymentWithRefund(paymentId: string, cashOut: CashOut) {
        return this.api.patchDotNet(`payment/${paymentId}/void-check-and-refund`, cashOut);
    }

    voidPaymentWithNoRefund(paymentId: string): Promise<any> {
        return this.api.patchDotNet('Payment/' + paymentId + '/VoidNoRefund', null);
    }

    partialRefund(paymentId: string, amount: number): Promise<any> {
        return this.api.patchDotNet(`payment/${paymentId}/partial-refund/${amount}`, null);
    }

    InvoiceToCredit(paymentId: string, policyId: string): Promise<any> {
        return this.api.patchDotNet('Payment/' + paymentId + '/InvoiceToCredit/' + policyId, null);
    }

    voidToCredit(paymentId: string, policyId: string): Promise<any> {
        return this.api.patchDotNet('Payment/' + paymentId + '/VoidToCredit/' + policyId, null);
    }

    getPaymentSummaryById(id: string) {
        return this.api.getSingleDotNet(`PaymentSummary/${id}`, null, () => new PaymentSummary());
    }

    getPaymentItemSummary(paymentId: string): Promise<PaymentItemSummary[]> {
        return this.api.getArrayDotNet(`PaymentItemSummary`, { paymentId_eq: paymentId }, () => new PaymentItemSummary());
    }

    getPaymentItemsByInvoiceId(invoiceId: string): Promise<PaymentItemSummary[]> {
        return this.api.getArrayDotNet(`PaymentItemSummary`, { invoiceId_eq: invoiceId }, () => new PaymentItemSummary());
    }

    uploadCreditCardTransactionsXlsx(xlsxDataUrl: Blob) {
        this.api.postNode("accounting/purchase-order-transaction-upload", xlsxDataUrl);
    }
}
