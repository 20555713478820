import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CommissionOpportunityRecipient {
    @keyColumn() id: string;
    @column() commissionTypeId: string;
    @column() entityId: string;
    @column() maxAllowed: number;
    @column() weight: number;
    @column() languages: string;
    @column() onHoldStartDate: Date;
    @column() onHoldEndDate: Date;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
}


@dataSource()
export class CommissionOpportunityRecipientSummary {
    @keyColumn() id: string;
    @column() commissionTypeId: string;
    @column() entityId: string;
    @column() maxAllowed: number;
    @column() weight: number;
    @column() languages: string;
    @column() onHoldStartDate: Date;
    @column() onHoldEndDate: Date;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() employeeName: string;
    @column() employeeEmail: string;
    @column() commissionTypeName: string;
}