import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";

@Component({
    selector: 'app-request-service',
    templateUrl: './request-service.component.html',
    styleUrls: ['./request-service.component.css']
})
export class RequestServiceComponent implements OnInit {

    orderUrl = '';
    safeOrderUrl: any;
    constructor(private entityApi: EntityApiService,
        private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private settingsApi: SettingsApiService) { }

    async ngOnInit() {
        this.activatedRoute.params.subscribe(async params => {
            if (params.id) {
                let userId = (await this.entityApi.getLoggedInUser()).id;
                let url = await this.settingsApi.getCustomerPortalBaseUrl() as string;
                if (document.location.host === 'localhost:4203') {
                    this.orderUrl = 'http://localhost:8000/real-estate-login/' + params.id + '/' + userId;
                } else {
                    this.orderUrl = `${url}real-estate-login/${params.id}/${userId}`;
                }
                this.safeOrderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.orderUrl);
            }
        });

    }

}
