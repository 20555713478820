import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CommissionOpportunity {
    @keyColumn() id: string;
    @column() commissionTypeId: string;
    @column() recordId: string;
    @column() ownerId: string;
    @column() commissionAmount: number;
    @column() earnedDate: Date;
    @column() paidDate: Date;
    @column() expirationDate: Date;
    @column() followUpDate: Date;
    @column() lostDate: Date;
    @column() status: string;
    @column() assignedDate: Date;
    @column() preferredLanguage: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() earnableDate: Date;
    @column() commissionOpportunityStatusId: string;
    
}

export class CommisionOpportunityHistory {
    id: string;
    createdDate: Date;
    createdById: string;
    createdByName: string;
    text: string;
    type: string;
    parentNoteId: string;
}


@dataSource()
export class PolicyRenewalOfferSummary {
    @column() planId: string;
    @column() policyId: string;
    @column() basePrice: number;
    @column() basePriceMonthly: number;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() optionPrice: number;
    @column() optionPriceMonthly: number;
    @column() happyHomiversaryDiscount: number;
    @column() preferredCustomerDiscount: number;
    @column() planName: string;
    @column() versionId: string;
    @column() summary: string;
    @column() serviceFee: number;
    @column() planColor: string;
    @column() description: string;
}