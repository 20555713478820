import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailArgs, EmailAddress } from '@cogent/shared/models/other/email-args.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EmailComposeModalComponent } from './email-compose-modal/email-compose-modal.component';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module'
import { MatChipsModule } from '@angular/material/chips';


@Component({
  selector: 'app-email-compose-link',
  templateUrl: './email-compose-link.component.html',
  styleUrls: ['./email-compose-link.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DragDropModule,
    RichTextAndSnippetModule
]
})
export class EmailComposeLinkComponent implements OnInit {
  @Input() emailAddress: string;
  @Input() display: string;
  @Input() policyId: string;
  @Input() workOrderId: string;
  @Input() taskId: string;
  @Input() commissionOpportunityId: string;

  @Input() hideIcon = false;
  constructor(private missionService: MissionService, 
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  showEmail() {
    const emailArgs = new EmailArgs();
    emailArgs.useSignature = true;
    emailArgs.toAddresses = [];
    emailArgs.toAddresses.push(new EmailAddress(this.emailAddress, this.display));
    emailArgs.policyId = this.policyId;
    emailArgs.workOrderId = this.workOrderId;
    emailArgs.taskId = this.taskId;
    emailArgs.commissionOpportunityId = this.commissionOpportunityId;


    const ref = this.dialog.open(EmailComposeModalComponent, { data: emailArgs });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.missionService.showSuccessToast('Message Sent');
      }
    });
  }

}
