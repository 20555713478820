import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { FileModel } from '@cogent/shared/models/common/file.model';
import { Language, Template } from '@upkeeplabs/models/cogent';



@Injectable({ providedIn: 'root' })
export class TemplateApiService {
    constructor(private api: ApiService) { }

    getAllContractTemplates(): Promise<any[]> {
        return this.api.getArrayDotNet(`template/contract-templates`);
    }

    saveContractTemplate(template: FileModel) {
        return this.api.postSingleDotNet(`template/contract-template`, template);
    }

    getMaintenanceServiceContractTemplates() {
        return this.api.getArrayNode('template', { templateKey_like: 'maintenance-service-contract' }, () => new Template());
    }

    deleteContractTemplate(template: FileModel) {
        return this.api.deleteDotNet(`template/contract-template/${template.name}`);
    }

    getLayoutTemplates() {
        return this.api.getArrayDotNet(`template/layout-templates`);
    }

    getV2TemplateHTML(templateKey: string, body: any) {
        return this.api.postTextNode(`template/${templateKey}/html`, body);
    }

    getV2Template(templateKey: string, body: any) {
        return this.api.postSingleNode(`template/${templateKey}`, body);
    }

    getLayoutTemplateText(fileName: string): Promise<string> {
        return this.api.getTextDotNet(`template/layout-template/${fileName}`);
    }

    saveLayoutTemplateText(fileName: string, file: string) {
        return this.api.putDotNet(`template/layout-template/${fileName}`, file, false);
    }

    deleteLayoutTemplate(fileName: string) {
        return this.api.deleteDotNet(`template/layout-template/${fileName}`);
    }

    saveTemplateEnvelope(template: Template) {
        template.templateConfiguration = JSON.stringify(template.templateConfigurationObject);
        return this.api.postSingleDotNet(`template/template-envelope`, template);
    }

    deleteTemplateEnvelope(id: string) {
        return this.api.deleteDotNet(`template/template-envelope/${id}`);
    }

    getAllTemplateEnvelopes(): Promise<Template[]> {
        return this.api.getArrayDotNet(`template/template-envelope`, null, () => new Template());
    }

    getFullTemplateEnvelope(id: string): Promise<Template> {
        return this.api.getSingleDotNet(`template/template-envelope/${id}`, null, () => new Template());
    }

    getTemplateEnvelopeHtml(templateKey: string): Promise<string> {
        return this.api.getTextDotNet(`template/template-envelope/${templateKey}/html`);
    }

    getComposedSubjectAndMessage(templateKey: string): Promise<any> {
        return this.api.getSingleDotNet(`template/template-envelope/${templateKey}/composed-subject-and-template`);
    }

    getLanguages(): Promise<Language[]> {
        return this.api.getArrayDotNet('Language', null, () => new Language());
    }

    getPolicyCanceledReasonEmailTemplates() {
        return this.api.getArrayNode('template?description_eq=policy-cancellation')
    }
}
