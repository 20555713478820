import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CommissionOpportunitySummary {
    @keyColumn() id: string;
    @column() commissionTypeId: string;
    @column() recordId: string;
    @column() ownerId: string;
    @column() commissionAmount: number;
    @column() earnedDate: Date;
    @column() paidDate: Date;
    @column() expirationDate: Date;
    @column() followUpDate: Date;
    @column() lostDate: Date;
    @column() status: string;
    @column() assignedDate: Date;
    @column() preferredLanguage: string;
    @column() ownerName: string;
    @column() ownerEmail: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() phone: string;
    @column() commissionTypeName: string;
    @column() earnableDate: Date;
    @column() statusName: string;
    @column() commissionOpportunityStatusId: string;
    @column() propertyAddress: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() buyersAgentId: string;
    @column() buyersAgentName: string;
}