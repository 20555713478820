import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource() export class JobItemDetail {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() propertyId: string;
    @column() itemName: string;
    @column() modelNo: string;
    @column() serialNo: string;
    @column() age: string;
    @column() createdById?: string;
    @column() isCashOut?: boolean;
    @column() createdDate?: Date;
    @column() lastModifiedById?: string;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() location: string;
    @column() brand: string;
    @column() subBrand: string;
}