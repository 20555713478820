import { Injectable } from '@angular/core';
import { PolicyHistoryItemModel } from '@cogent/shared/models/policies/policy-history-item.model';
import { ExpensesByTradeForCustomer } from '@cogent/shared/models/policies/expenses-by-trade-for-customer.model';
import { PolicyRenewalModel } from '@cogent/shared/models/sales/policy-renewal.model';
import { PolicyCoverageResult } from '@cogent/shared/models/policies/policy-coverage-result.model';
import { NewPolicyModel } from '@cogent/shared/models/sales/new-policy-legacy.model';
import { PolicyModel } from '@cogent/shared/models/policies/policy-legacy.model';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { Address, Entity, InvoiceSummary, Policy, PromotionCode } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { StripeApiService } from '@cogent/client/shared/services/api/stripe-api.service';
import { AddressApiService } from '@cogent/client/shared/services/api/address-api.service';
import { PromotionCodeSummary } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Dictionary, LandingPageRedirect, MarketingSource, PaymentItemSummary, StripeTransactionCharge, WebQuote } from '@upkeeplabs/models/cogent';
import { AuthService } from '@cogent/client/auth';
import { AccountingItem } from '@upkeeplabs/models/cogent';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { CoverageType } from '@cogent/client/shared/models/plans/plan-client.model';

@Injectable({ providedIn: "root" })
export class CustomerRepositoryService {
    constructor(
        private api: ApiService,
        private addressApi: AddressApiService,
        private stripeApi: StripeApiService,
        private authService: AuthService,
        private entityApi: EntityApiService,
    ) { }

    getPoliciesForCustomer(customerId: string): Promise<PolicySummary[]> {
        return this.api.getArrayDotNet<PolicySummary>("policySummary", { holderId_eq: customerId }, () => new PolicySummary());
    }

    getLandingPageConfig() {
        return this.api.getSingleNode('quote/landing-page-config', null, () => new Dictionary());
    }

    getPoliciesForLogin(loginId: string, customerId: string): Promise<PolicySummary[]> {
        if (loginId) {
            return this.api.getArrayDotNet<PolicySummary>('policySummary', { loginId_eq: loginId }, () => new PolicySummary());
        }
        else {
            return this.getPoliciesForCustomer(customerId);
        }
    }

    getCustomerCreditBalance(customerId: string) {
        return this.api.getSingleNode(`entity/customer-credit-balance/${customerId}`);
    }

    getStripeCardForPolicy(customerId: string, stripCardId: string) {
        return this.api.getSingleNode(`stripe/${customerId}/${stripCardId}/get-card`);
    }

    getMarketingSourceByName(name: string) {
        return this.api.getArrayNode(`policy/marketing-source-by-name/${name}`, null, () => new MarketingSource());
    }

    getPaymentsByLogin(loginId: string, startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`PaymentItemSummary`, { loginId_eq: loginId, PaymentCreatedDate_gte: startDate, PaymentCreatedDate_lte: endDate, orderby: 'PaymentCreatedDate', voidDate_eq: '{{null}}' }, () => new PaymentItemSummary());
    }

    getMaintenanceServicePaymentsByLogin(loginId: string, startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`MaintenanceServicePaymentItemSummary`, { loginId_eq: loginId, createdDate_gte: startDate, createdDate_lte: endDate, orderby: 'CreatedDate' }, () => new PaymentItemSummary());
    }

    getPaymentsByPropertyManagerId(propertyManagerId: string, startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`PaymentItemSummary`, { propertyManagerId_eq: propertyManagerId, PaymentCreatedDate_gte: startDate, PaymentCreatedDate_lte: endDate, orderby: 'PaymentCreatedDate', voidDate_eq: '{{null}}' }, () => new PaymentItemSummary());

    }

    saveWebQuote(quote: WebQuote) {
        return this.api.postNode(`quote/save`, quote);
    }

    getWebQuote(id: string): Promise<WebQuote> {
        return this.api.getSingleNode(`quote/get/${id}`, null, () => new WebQuote());
    }

    updateWebQuoteDetails(id: string, details: string) {
        return this.api.patchNode(`quote/update-quote-details/${id}`, { details });
    }

    getUpcommingAppointments(loginId: string) {
        return this.api.getArrayDotNet(`WorkOrderSummary`, { loginId_eq: loginId, scheduledDate_gte: new Date(), CancelledDate_eq: '{{null}}', orderby: 'scheduledDate', take: 3, select: 'id,number,itemName,propertyAddress,homeownerName,scheduledDate,scheduledStartWindow,scheduledEndWindow' });
    }

    getAllAppointmentsForLogin(loginId: string) {
        return this.api.getArrayDotNet(`WorkOrderSummary`, { loginId_eq: loginId, scheduledDate_neq: '{{null}}', CancelledDate_eq: '{{null}}', orderby: 'scheduledDate', select: 'Id,Number,ItemName,PropertyAddress,DateCompleted,TradeId,SLAStatus,Status,ItemId,ScheduledDate,ScheduledStartWindow,ScheduledEndWindow,ContractorId,TechnicianId,PropertyCity,PropertyPostalCode' });

    }

    getLandingPageRedirect(urlKey: string) {
        return this.api.getSingleNode(`LandingPageRedirect`, { urlKey_eq: urlKey }, () => new LandingPageRedirect());
    }

    getPolicySummaryFromKey(altKey: string) {
        return this.api.getSingleDotNet(`CustomerOnBoarding/${altKey}/policy-summary`, null, () => new PolicySummary());
    }

    completeOnboarding(args) {
        return this.api.postSingleDotNet(`CustomerOnBoarding/${args.key}/save-onboarding`, args);
    }

    completeExistingOnboarding(args) {
        return this.api.postSingleDotNet(`CustomerOnBoarding/${args.key}/save-existing-onboarding`, args);
    }

    async getPoliciesForLoggedInUser() {


        const user = await this.entityApi.getLoggedInUser();

        if (user?.type === 'Agent') {
            const propManagerPolicies = await this.api.getArrayDotNet<PolicySummary>(`PolicySummary`, { PropertyManagerId_eq: user.id }, () => new PolicySummary());
            const individualPolicies = await this.api.getArrayDotNet<PolicySummary>('policySummary', { loginId_eq: user.loginId }, () => new PolicySummary());
            const combined =  propManagerPolicies.concat(individualPolicies);
            return combined;
        } else if (user) {
            return this.api.getArrayDotNet<PolicySummary>('policySummary', { loginId_eq: user.loginId }, () => new PolicySummary());
        } else if (this.authService.parsedJwt?.sub) {
            return this.api.getArrayDotNet<PolicySummary>('policySummary', { holderId_eq: this.authService.parsedJwt.sub });
        }
    }

    getCustomerPortalEnabled(): Promise<boolean> {
        return this.api.getSingleDotNet(`singleton/enable-customer-portal`);
    }

    getMarketingSources() {
        return this.api.getArrayNode(`Policy/marketing-sources`);
    }

    async saveCustomerAndPhone(customer: Entity) {
        const promises = [];
        await this.api.postVoidDotNet('Entity/save-entity', customer);

        for (const phoneNumber of customer.phoneNumbers) {
            if (UtilitiesService.validatePhoneNumber(phoneNumber.number)) {
                phoneNumber.entityId = customer.id;
                if (!phoneNumber.id) {
                    phoneNumber.id = UtilitiesService.newid();
                }
                promises.push(this.api.postVoidDotNet('Entity/save-entity-phone', phoneNumber));
            }
        }

        return Promise.all(promises);
    }

    getJobItemInExcludedArea(workOrderItemId: string, policyId: string) {
        return this.api.getSingleNode(`work-order/job-item-in-excluded-product-area`, { workOrderItemId, policyId });
    }

    async getRecentWorkOrdersForLoggedInUser(startDate: Date = null, recordCount = 3) {
        const user = await this.entityApi.getLoggedInUser();
        if (user == null) {
            return [];
        }
        if (user.type === 'Agent') {
            return this.api.getArrayDotNet('WorkOrderSummary',
                {
                    propertyManagerId_eq: user.id,
                    orderBy: 'CreatedDate Descending',
                    take: recordCount,
                    select: 'id,itemName,itemId,createdDate,propertyAddress,holderId,status,number,linesJson',
                    createdDate_lt: startDate
                },
                () => new WorkOrderSummaryClient());
        } else {
            return this.api.getArrayDotNet('WorkOrderSummary',
                {
                    loginId_eq: user.loginId,
                    orderBy: 'CreatedDate Descending',
                    take: recordCount,
                    select: 'id,itemName,itemId,createdDate,propertyAddress,holderId,status,number,linesJson',
                    createdDate_lt: startDate
                },
                () => new WorkOrderSummaryClient());
        }
    }

    getRecentWorkOrdersForCustomer(loginId: string, customerId: string, startDate: Date = null, recordCount = 3) {
        if (!startDate) {
            startDate = new Date();
        }

        if (loginId) {

            return this.api.getArrayDotNet('WorkOrderSummary',
                {
                    loginId_eq: loginId,
                    orderBy: 'CreatedDate Descending',
                    take: recordCount,
                    select: 'id,itemName,itemId,createdDate,propertyAddress,holderId,status,number,linesJson',
                    createdDate_lt: startDate
                },
                () => new WorkOrderSummaryClient());
        }
        else {

            return this.api.getArrayDotNet('WorkOrderSummary',
                {
                    holderId_eq: customerId,
                    orderBy: 'CreatedDate Descending',
                    take: recordCount,
                    select: 'id,itemName,itemId,createdDate,propertyAddress,holderId,status,number,linesJson',
                    createdDate_lt: startDate
                },
                () => new WorkOrderSummaryClient());
        }

    }

    async getCurrentPromotionCodes(showInCogentWidget: boolean = null) {
        return this.api.getArrayNode('promotion-code/current?showInCogentWidget=' + showInCogentWidget);
    }

    getPromotionCode(code: string, coverageType: CoverageType): Promise<PromotionCodeSummary> {
        return this.api.getSingleNode(`Promotion-Code/internal/${code}/${coverageType}`, null, () => new PromotionCodeSummary());
    }

    getHomeownerRewards(entityId: string) {
        return this.api.getSingleNode(`Promotion-Code/homeowner-rewards/${entityId}`)
    }

    createReferralCode(entityId) {
        return this.api.postSingleNode(`promotion-code/create-referral-code/${entityId}`);
    }

    createReferralCredits(entityId) {
        return this.api.postSingleNode(`promotion-code/generate-referral-credits/${entityId}`);
    }

    async getCustomerReferralCode(entityId: string) {
        return await this.api.getSingleNode('PromotionCode', { entityId_eq: entityId, isReferralCode_eq: true });
    }

    async getReferralCode(id: string) {
        return await this.api.getSingleNode(`promotion-code/get-referral-code/${id}`)
    }

    async updatePromotionCode(id: string, code: string) {
        return await this.api.patchSingleNode(`Promotion-Code/update-referral-code/${id}`, { code })
    }

    async getCustomerMailingAddress(customerId: string) {
        const entity = await this.api.getSingleDotNet(`Entity/${customerId}`, null, () => new Entity());
        return this.api.getSingleDotNet(`Address/${entity.addressId}`);
    }

    sendPasswordResetEmail(emailAddress: string) {
        return this.api.patchSingleDotNet(`registration/send-password-reset-code/${emailAddress}`, null);
    }

    resetPasswordFromCode(securityCode: number, password: string): Promise<boolean> {
        return this.api.patchSingleDotNet(`registration/reset-password-from-code`, { securityCode, password });
    }

    doPostalCodeLookup(postalCode: string): Promise<Address> {
        return this.addressApi.doPostalCodeLookup(postalCode);
    }

    findExistingPoliciesByEmail(email: string): Promise<any[]> {
        return this.api.getArrayDotNet(`Registration/email-exists-on-active-policy`, { email });
    }

    findExistingEntitiesByEmail(email: string, type: string): Promise<any> {
        return this.api.getArrayDotNet('registration/existing-entities-by-email', { email, type });
    }

    // loginExists(userName: string): Promise<boolean> {
    //     return this.api.getSingle<boolean>(`Login/exists/${userName}`);
    // }

    sendVerificationCode(sendCodeArgs) {
        return this.api.patchDotNet('Registration/send-verification-code', sendCodeArgs);
    }

    completeRegistrationWithCode(verifyCodeArgs): Promise<boolean> {
        return this.api.postSingleDotNet(`Registration/complete-registration-with-code`, verifyCodeArgs);
    }

    attachRegistrationWithCode(verifyCodeArgs): Promise<boolean> {
        return this.api.postSingleDotNet(`Registration/attach-registration-with-code`, verifyCodeArgs);
    }

    completeNewRegistration(registrationArgs) {
        return this.api.postSingleDotNet(`Registration/complete-new-registration`, registrationArgs);
    }

    updateCustomer(customer: Entity) {
        return this.api.postSingleNode('entity/update-customer', customer);
    }

    loginExists(userName: string, type: string = 'Customer') {
        return this.api.getSingleDotNet<boolean>(`Registration/exists`, { userName, type });
    }

    async updateEntity(entity: Entity): Promise<Entity> {
        if (!entity.id) {
            entity.id = UtilitiesService.newid();
        }

        if (entity.address && !entity.address.id) {
            entity.address.id = UtilitiesService.newid();
        }

        if (entity.address) {
            entity.addressId = entity.address.id;
        }

        if (entity.type === 'Employee') {
            entity.name = entity.firstName + ' ' + entity.lastName;
        }

        await this.api.postVoidDotNet('Address', entity.address);
        await this.api.postVoidDotNet('Entity', entity);
        const promises = [];
        entity.phoneNumbers.forEach(phoneNumber => {
            if (!phoneNumber.id) {
                phoneNumber.id = UtilitiesService.newid();
            }
            phoneNumber.entityId = entity.id;

            if (phoneNumber.number) {
                promises.push(this.api.postVoidDotNet('PhoneNumber', phoneNumber));
            } else {
                promises.push(this.api.deleteDotNet('PhoneNumber/' + phoneNumber.id));
            }
        });
        await Promise.all(promises);
        return entity;
    }

    getPropertyMeta(streetAddress: string, postalCode: string): Promise<PropertyMetaModel> {

        return this.api.getSingleDotNet('PropertyMeta', {
            address: streetAddress,
            zip: postalCode
        });
    }

    checkHomeownerPostalCode(postalCode: string) {
        return this.api.getSingleNode(`sales/homeowner-postal-code-covered/${postalCode}`)
    }

    async saveNewPolicy(policy: NewPolicyModel): Promise<PolicyModel> {

        if (!policy.policy.id || policy.policy.id === "") {
            policy.policy.id = UtilitiesService.newid();
        }
        const newPolicy = await this.api.postSingleDotNet('policy/CreateNew', policy, () => new PolicyModel());
        return newPolicy;
    }

    checkForCoverage(address: Address): Promise<PolicyCoverageResult> {
        return this.api.postSingleDotNet('Address/IsCovered', address, () => new PolicyCoverageResult());
    }

    cancelWorkOrder(workOrderId: string): Promise<any> {
        return this.api.deleteDotNet('WorkOrder/' + workOrderId + '/Cancel');
    }

    getWorkOrdersForCustomerAndProperty(customerId: string, propertyId: string) {
        return this.api.getArrayDotNet(
            'workOrderSummary',
            {
                holderId_eq: customerId,
                propertyId_eq: propertyId,
                orderBy: 'CreatedDate Descending',
                select: 'id,itemName,itemId,createdDate,propertyAddress,status,holderId,linesJson',
            },
            () => new WorkOrderSummaryClient());
    }

    renewPolicy(policyId: string, policyRenewal: PolicyRenewalModel) {
        return this.api.postSingleDotNet<Policy>('Policy/' + policyId + '/renew', policyRenewal);
    }

    getUnpaidInvoices(loginId: string, customerId: string, spanEnd: string | Date = '{{now}}'): Promise<InvoiceSummary[]> {


        const twoYearsAgo = new Date();
        twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
        if (loginId) {
            return this.api.getArrayDotNet('invoiceSummary', {
                loginId_eq: loginId,
                orderBy: 'CreatedDate Descending',
                amountDue_gt: 0,
                voidDate_eq: '{{null}}',
                policyCanceledDate_eq: '{{null}}',
                dueDate_gt: twoYearsAgo,
                dueDate_lt: spanEnd
            }, () => new InvoiceSummary());
        } else {
            return this.api.getArrayDotNet('invoiceSummary', {
                holderId_eq: customerId,
                orderBy: 'CreatedDate Descending',
                amountDue_gt: 0,
                voidDate_eq: '{{null}}',
                policyCanceledDate_eq: '{{null}}',
                dueDate_lt: spanEnd,
                dueDate_gt: twoYearsAgo,
            }, () => new InvoiceSummary());
        }
    }

    getUnpaidPropertyManagerInvoices(loginId: string) {
        const twoYearsAgo = new Date();
        twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
        return this.api.getArrayDotNet('invoiceSummary', {
            PropertyManagerLoginId_eq: loginId,
            orderBy: 'CreatedDate Descending',
            amountDue_gt: 0,
            voidDate_eq: '{{null}}',
            dueDate_lt: '{{now}}',
            dueDate_gt: twoYearsAgo,
        }, () => new InvoiceSummary());
    }

    async getUnpaidInvoicesForLoggedInUser() {
        const user = await this.entityApi.getLoggedInUser();
        if (user.type === 'Agent') {
            return this.api.getArrayDotNet('invoiceSummary', {
                propertyManagerId_eq: user.id,
                orderBy: 'CreatedDate Descending',
                amountDue_gt: 0,
                voidDate_eq: '{{null}}',
                dueDate_lt: '{{now}}'
            }, () => new InvoiceSummary());
        } else {
            return this.api.getArrayDotNet('invoiceSummary', {
                loginId_eq: user.id,
                orderBy: 'CreatedDate Descending',
                amountDue_gt: 0,
                voidDate_eq: '{{null}}',
                dueDate_lt: '{{now}}'
            }, () => new InvoiceSummary());
        }
    }

    getInvoicesForProperty(customerId: string, propertyId: string): Promise<InvoiceSummary[]> {
        return this.api.getArrayDotNet('invoiceSummary', {
            holderId_eq: customerId,
            propertyId_eq: propertyId,
            orderBy: 'CreatedDate Descending',
            voidDate_eq: '{{null}}'
        }, () => new InvoiceSummary());
    }

    getExpensesByTradeForLogin(loginId: string): Promise<ExpensesByTradeForCustomer[]> {
        return this.api.getArrayDotNet(`entity/${loginId}/expenses-by-trade-login`, null, () => new ExpensesByTradeForCustomer());
    }

    getCustomerAccountingItems(customerId: string, propertyId: string): Promise<AccountingItem[]> {

        return this.api.getArrayDotNet('accountingItem', {
            customerId_eq: customerId,
            propertyId_eq: propertyId,
            voidDate_eq: '{{null}}',
        }, () => new AccountingItem());
    }

    getPolicyAccountingItems(policyId: string): Promise<AccountingItem[]> {

        return this.api.getArrayDotNet('accountingItem', {
            policyId_eq: policyId,
            voidDate_eq: '{{null}}',
            orderby: 'Date'
        }, () => new AccountingItem());
    }

    getPolicyCreditAmount(policyId: string): Promise<number> {
        return this.api.getSingleDotNet(`AccountingItem/${policyId}/credit-balance`, null, null);
    }

    getOpenWorkOrderCount(loginId: string, customerId: string) {
        if (loginId) {
            return this.api.getSingleDotNet('WorkOrderSummary/count', {
                loginId_eq: loginId,
                status_ncontains: 'Complete,Closed,Paid,Canceled,Cancelled,Invoiced',
            });
        } else {
            return this.api.getSingleDotNet('WorkOrderSummary/count', {
                holderId_eq: customerId,
                status_ncontains: 'Complete,Closed,Paid,Canceled,Cancelled,Invoiced',
            });
        }
    }

    async getOpenWorkOrderCountForLoggedInUser() {
        const user = await this.entityApi.getLoggedInUser();
        if (user.type === 'Agent') {
            return this.api.getSingleDotNet('WorkOrderSummary/count', {
                propertyManagerId_eq: user.id,
                status_ncontains: 'Complete,Closed,Paid,Canceled,Cancelled,Invoiced',
            });
        } else {
            return this.api.getSingleDotNet('WorkOrderSummary/count', {
                loginId_eq: user.loginId,
                status_ncontains: 'Complete,Closed,Paid,Canceled,Cancelled,Invoiced',
            });
        }
    }

    getCoverageByKey(key: string) {
        return this.api.getArrayDotNet(`CustomerOnBoarding/${key}/coverage`);
    }

    getCoverageById(id: string) {
        return this.api.getArrayDotNet(`CustomerOnBoarding/${id}/coverage-by-id`);
    }

    createCharge(customerId: string, charge: StripeTransactionCharge) {
        return this.stripeApi.createCharge(customerId, charge as any);
    }



    getPolicyHistory(customerId: string, startDate: Date = null, count: number = 10): Promise<PolicyHistoryItemModel[]> {
        if (!startDate) {
            startDate = new Date();
        }
        return this.api.getArrayDotNet('PolicyHistoryItem',
            {
                holderId_eq: customerId,
                take: count,
                statusDate_lt: startDate,
                orderBy: 'StatusDate Descending',
            },
            () => new PolicyHistoryItemModel());
    }

    createShortLink(url: string, source: string, description: string, expires: string) {

    }

    getReferralCodeLink(promotionCodeName: string) {
        return this.api.getSingleNode(`short-link/referral-link/${promotionCodeName}`)
    }
}
