import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { Note, NoteSummary, WorkOrderSummary } from '@upkeeplabs/models/cogent';
import { NoteAttachmentModel } from '@cogent/client/shared/models/notes/note-attachment.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { NoteClient } from '@cogent/client/shared/models/common/note-client.model';

@Injectable({ providedIn: 'root' })
export class NoteApiService {

    constructor(
        private api: ApiService,
        private entityApi: EntityApiService
    ) { }

    getAttachmentDownloadUrl(noteAttachment: NoteAttachmentModel, showContent = false) {
        return ApiService.endPointDotNet + "Note/Attachment/" + noteAttachment.noteId + "/" + noteAttachment.name + "?showContent=" + showContent;
    }

    async updateNoteReferrence(historyItemId: string, attachedTo: 'Policy' | 'WorkOrder' | 'Entity', id: string) {
        switch (attachedTo) {
            case 'Entity':
                return this.api.patchNode(`Note/${historyItemId}`, { entityId: id });
            case 'Policy':
                return this.api.patchNode(`Note/${historyItemId}`, { policyId: id });
            case 'WorkOrder':
                const wos = await this.api.getSingleNode(`WorkOrderSummary/${id}`, null, () => new WorkOrderSummary());
                return this.api.patchNode(`Note/${historyItemId}`, { workOrderId: id, policyId: wos?.policyId });
        }
    }

    async updateAmazonContactPolicy(id: string, newRecordId: string, attachedTo: 'Policy' | 'WorkOrder' | 'Entity') {
        switch (attachedTo) {
            case 'Policy':
                return this.api.patchNode(`AmazonContact/${id}`, { policyId: newRecordId });
            case 'WorkOrder':
                const wos = await this.api.getSingleNode(`WorkOrderSummary/${newRecordId}`, null, () => new WorkOrderSummary());

                return this.api.patchNode(`AmazonContact/${id}`, { workOrderId: newRecordId, policyId: wos?.policyId });
            case 'Entity':
                throw 'Not Implemented';

        }
    }

    toggleNoteRemoved(id: string, removed: boolean) {
        return this.api.patchDotNet(`Note/${id}`, { removed });
    }

    updateNoteType(id: string, type: string) {
        return this.api.patchNode(`Note/${id}`, { type });
    }

    getAttachmentThumbnailUrl(noteAttachment: NoteAttachmentModel) {
        return ApiService.endPointDotNet + "Note/Attachment/" + noteAttachment.noteId + "/" + noteAttachment.name + "/Thumbnail";
    }

    async getPrivateWorkOrderNotes(workOrderId: string) {
        const loggedInUser = await this.entityApi.getLoggedInUser();

        return this.api.getArrayDotNet('NoteSummary', { workOrderId_eq: workOrderId, createdById_eq: loggedInUser.id, isPrivate_eq: true, orderBy: 'CreatedDate' }, () => new NoteSummary());
    }

    async getPrivatePolicyNotes(policyId: string) {
        const loggedInUser = await this.entityApi.getLoggedInUser();
        return this.api.getArrayNode('NoteSummary', { policyId_eq: policyId, createdById_eq: loggedInUser.id, isPrivate_eq: true, orderBy: 'CreatedDate' }, () => new NoteSummary());
    }

    getPolicyNotes(policyId: string) {
        return this.api.getArrayDotNet('NoteSummary', { policyId_eq: policyId, orderBy: 'CreatedDate descending' }, () => new NoteSummary());
    }

    getQuoteNotes(quoteId: string) {
        return this.api.getArrayNode('NoteSummary', { quoteId_eq: quoteId, orderBy: 'CreatedDate descending' }, () => new NoteSummary());
    }

    async transferPolicyNotes(fromId: string, toId: string) {
        const allPromises: Promise<string>[] = [];
        const notes = await this.api.getArrayDotNet(`Note`, { policyId_eq: fromId }, () => new Note());
        for (const note of notes) {
            note.policyId = toId;
            allPromises.push(this.saveNote(note));
        }
        return await Promise.all(allPromises);
    }

    async getEntityPinnedNote(entityId: string): Promise<Note> {
        let note = await this.api.getSingleDotNet(`Note`, { entityId_eq: entityId, pinned_eq: true }, () => new Note());
        if (!note) {
            note = new Note();
            note.id = UtilitiesService.newid();
            note.entityId = entityId;
            note.pinned = true;
        }

        return note;
    }

    getTaskNotes(taskId: string) {
        return this.api.getArrayDotNet('Note', { taskId_eq: taskId, orderBy: 'CreatedDate descending' }, () => new Note());
    }

    async updateOldNote(oldNoteId: string, newNote: NoteClient) {
        await this.api.patchSingleNode(`note/${oldNoteId}`, { parentNoteId: newNote.id, isUpdated: true });
    }

    async saveNote(note: Note): Promise<any> {
        if (!note.createdById) {
            const user = await this.entityApi.getLoggedInUser(false);
            note.createdById = user.id;
        }

        note.id = await this.api.postIdDotNet("Note", note);
        return note.id;
    }

    getAttachmentList(ids: string[]) {
        return this.api.postArrayDotNet('note/attachment/list', ids);
    }

    async toggleReaction(policyId: string, id: string, newNoteText: string, adding: boolean, workOrderId: string = null) {
        const user = await this.entityApi.getLoggedInUser(false);

        if (adding) {
            const note = new Note();
            note.id = UtilitiesService.newid();
            note.parentNoteId = id;
            note.createdById = user.id;
            note.createdDate = new Date();
            note.policyId = policyId;
            note.workOrderId = workOrderId;
            note.noteText = 'reaction by ' + user.name + (newNoteText ? ' ' + newNoteText : '');
            return await this.api.postDotNet('Note', note);
        }
        else {
            const note = new Note();
            note.id = id;
            return await this.deleteNote(note);
        }
    }

    setPinnedNote(id: string, pinned: boolean) {
        return this.api.patchSingleDotNet(`note/${id}`, {
            pinned
        });
    }

    async getStickyNotes(startDate: Date, endDate: Date, mineOnly: boolean) {
        const params: any = {
            createdDate_gte: startDate,
            createdDate_lte: endDate,
            orderby: 'CreatedDate desc',
            isPrivate_eq: true,
        }

        if (mineOnly) {

            const user = await this.entityApi.getLoggedInUser(false);
            params.createdById_eq = user.id;
        }

        return this.api.getArrayDotNet('NoteSummary', params, () => new NoteSummary());
    }

    getWorkOrderNotes(workOrderId: string) {
        return this.api.getArrayDotNet("Note", { workOrderId_eq: workOrderId, orderBy: 'CreatedDate descending' }, () => new NoteClient());
    }

    saveNoteAttachment(noteId: string, attachment: NoteAttachmentModel) {
        return this.api.postVoidDotNet("Note/Attachment/" + noteId, attachment);
    }

    getAttachments(noteId: string) {
        return this.api.getArrayDotNet("Note/Attachment/" + noteId, null, () => new NoteAttachmentModel());
    }

    deleteAttachment(attachment: NoteAttachmentModel) {
        return this.api.deleteDotNet("Note/Attachment/" + attachment.noteId + "/" + attachment.name);
    }

    deleteNote(note: Note) {
        return this.api.deleteDotNet("Note/" + note.id);
    }

    undoDeleteNote(note: Note) {
        return this.api.patchDotNet("Note/" + note.id, { deletedDate: null });
    }
}
